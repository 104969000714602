<template lang="pug">
    section.bannerGaleriaTemplate
        .controls(v-if="length > 1")
            button.left(@click="toggleBanner(ativo - 1)")
                FontAwesomeIcon(:icon="icons.faChevronLeft")
            button.right(@click="toggleBanner(ativo + 1)")
                FontAwesomeIcon(:icon="icons.faChevronRight")
        slot
        slot(name="nav", :toggleBanner="toggleBanner")
</template>

<script>
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'

export default {
    name: "component-banner-galeria-template",
    data() {
        return {
            icons: {
                faChevronLeft, faChevronRight
            },
        }
    },
    props: {
        ativo: {
            type: Number,
            default: 0,
        },
        length: {
            type: Number,
        }
    },
    methods: {
        toggleBanner(index){
            if(index < 0)
                this.$emit('update:ativo', this.$props.length - 1)
            else if(index > this.$props.length - 1)
                this.$emit('update:ativo', 0)
            else
                this.$emit('update:ativo', index)
        }
    }
}
</script>

<style lang="stylus" scoped src="./BannerGaleriaTemplate.styl"></style>