<template lang="pug">
    Aside#asideVideo(:ativo="$store.state.asideVideo != null" @close="close()")
        video(:src="$store.state.asideVideo", controls, autoplay)
</template>

<script>
import Aside from '@components/Aside/Aside'

export default {
    name: "component-aside-video",
    components: {
        Aside,
    },
    mounted() {
    },
    methods: {
        close() {
            this.$store.commit('setAsideVideo', null)
        },
    }
}
</script>

<style lang="stylus" scoped src="./AsideVideo.styl"></style>